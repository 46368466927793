import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
export * from "@/lib/vue/plugins/vueQuery"

componentInitializer(() => {
  let el = document.getElementById("vue-artist-profile-page")

  if (el) {
    const ArtistProfilePage = defineAsyncComponent(() => import("@/pages/ArtistProfilePage/index.vue"))

    new Vue({
      el,
      render: (h) =>
        h(ArtistProfilePage, {
          props: {
            artist: JSON.parse(el.dataset.artist),
            sortOptions: JSON.parse(el.dataset.sortOptions),
            showPortfolio: JSON.parse(el.dataset.showPortfolio),
            isAdmin: JSON.parse(el.dataset.isAdmin),
          },
        }),
    })
    // Workaround to avoid the page to scroll all the way down
    $(window).scrollTop(0)
  }
})
